// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DatasourcesStatus } from '@/footer/DatasourcesStatus.organism';
import { useTranslation } from 'react-i18next';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { subscribe } from '@/utilities/socket.utilities';
import {
  GetFeedback,
  getFeedbackButton,
  getFeedbackScript,
  shouldFeedbackDisplay,
} from '@/getFeedback/GetFeedback.atom';
import { seeqVersion } from '@/services/systemConfiguration.utilities';
import { ButtonWithPopover } from '@seeqdev/qomponents';

interface FooterProps {
  // Number of messages to receive before we can determine that the Feedback javascript has either loaded or not
  messageThresholdForFeedbackLoading?: number;
}

export const Footer: React.FunctionComponent<FooterProps> = ({ messageThresholdForFeedbackLoading = 1 }) => {
  const { t } = useTranslation();
  const [serverLoad, setServerLoad] = useState({
    labelClass: '',
    loadPercentage: 0,
    description: '',
  });
  const interactiveSessionId = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.interactiveSessionId);
  const isDev = process.env.NODE_ENV === 'development';
  const target = useRef();

  const messageCount = useRef(0);
  const provideServerLoad = (message) => {
    messageCount.current += 1;
    setServerLoad({
      ...message?.serverLoad,
      description: `${message?.serverLoad.description} ${message?.consumption}`,
      loadPercentage: Math.round(message?.serverLoad.loadPercentage * 100),
      labelClass: getLabelClass(message?.serverLoad),
    });
  };

  const getLabelClass = _.cond([
    [_.conforms({ loadPercentage: (n: number) => n < 1 }), _.constant('label-success')],
    [_.conforms({ loadPercentage: (n: number) => n >= 1 && n < 2 }), _.constant('label-warning')],
    [_.stubTrue, _.constant('label-danger')],
  ]);

  useEffect(() => {
    const unsubscribe = subscribe({
      channelId: [SeeqNames.Channels.RequestsProgress, sqWorkbenchStore.interactiveSessionId],
      onMessage: provideServerLoad,
      useSubscriptionsApi: false,
    });
    return unsubscribe;
  }, [interactiveSessionId]);

  // Because we support sending feedback only to a percentage of users just enabling feedback doesn't guarantee
  // there is a feedback button - in those cases we don't want the server health to be displayed strangely indented.
  // Unfortunately mopininon does not have a way for us to know when the feedback button is done loading, so we have
  // to check on every re-render to make sure things look right :)
  const feedback = shouldFeedbackDisplay();
  const feedbackScriptLoading =
    feedback && (getFeedbackScript() == null || messageCount.current <= messageThresholdForFeedbackLoading);
  const feedbackButtonShown = feedback && getFeedbackButton() != null;

  return (
    <footer
      id="footer"
      data-testid="footer"
      className={classNames('mainFooter', 'flexColumnContainer', {
        footerWithFeedback: feedbackScriptLoading || feedbackButtonShown,
      })}>
      <GetFeedback />
      <div id="seeqVersion" className="inlineBlock">
        {seeqVersion()} {isDev && '[DEV]'}
      </div>
      <div className="flexColumnContainer autoMarginLeft">
        <DatasourcesStatus />

        {!_.isEmpty(serverLoad) && (
          <ButtonWithPopover
            placement="top"
            hasArrow
            tooltipPlacement="top"
            tooltip={t('SERVER_LOAD.TOOLTIP')}
            trigger={
              <div className="text-nowrap flexColumnContainer flexAlignCenter cursorPointer">
                <span className="sq-fairly-dark-gray cursorDefault ml5 mr5">|</span>
                <span className="sq-fairly-dark-gray pr5">{t('SERVER_LOAD.TITLE')}</span>
                <span className={classNames('label', serverLoad.labelClass)}>
                  {t('PERCENT', { PERCENT: serverLoad.loadPercentage })}
                </span>
              </div>
            }>
            <div id="serverLoadPopOver" className=" datasourcesPopup min-width-500">
              <div className="popover-header text-bold pt6 pl6 pb6 pr6 bg-gray">
                <span>{t('SERVER_LOAD.POPOVER_TITLE')}</span>
              </div>
              <div>
                <div className="flexColumnContainer flexCenter">
                  <div className="text-pre-wrap text-monospace">{serverLoad.description}</div>
                </div>
              </div>
            </div>
          </ButtonWithPopover>
        )}
      </div>
    </footer>
  );
};
