import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Icon } from '@seeqdev/qomponents';
import React from 'react';
import { RulesResultV1, sqTableOverridesApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { t } from 'i18next';
import {
  setEvaluationDetailColumn,
  setEvaluationResultsToView,
  setShowEvaluationDetailModal,
  setTableDefinition,
} from '@/tableDefinitionEditor/tableDefinition.actions';
import { sqTableDefinitionStore } from '@/core/core.stores';

export interface TableDefinitionRendererProps extends CustomCellRendererProps {
  tableDefinitionId: string;
}

export const TableDefinitionCellRenderer = (props: TableDefinitionRendererProps) => {
  const cellData = props.colDef?.field && props.data[props.colDef.field];
  if (!cellData) {
    return undefined;
  }

  let override = <></>;
  let info = <></>;

  if (cellData.override) {
    override = (
      <Icon
        icon="fa-times"
        large={true}
        extraClassNames="closeIcon ml5"
        onClick={() => deleteOverride(props)}
        tooltip={t('SCALING.OVERRIDE.DELETE_OVERRIDE_TOOLTIP')}
      />
    );
  }

  if (cellData.evaluationDetails) {
    info = (
      <Icon
        icon="fa-info-circle"
        large={true}
        extraClassNames="ml5"
        onClick={() => displayEvaluationDetails(cellData.evaluationDetails, props.colDef?.field)}
        tooltip={t('SCALING.EVALUATION_DETAILS_TOOLTIP')}
      />
    );
  }

  return (
    <div>
      {props.valueFormatted}
      {override}
      {info}
    </div>
  );
};

const deleteOverride = async (props: TableDefinitionRendererProps) => {
  if (props.colDef?.field === undefined || props.api === undefined) {
    return;
  }
  const columns = props.api.getColumns();
  const columnIdItemId = columns
    ?.find((c) => c.getColDef().headerName === SeeqNames.MaterializedTables.ItemIdColumn)
    ?.getColId();

  const columnIdDatumId = columns
    ?.find((c) => c.getColDef().headerName === SeeqNames.MaterializedTables.DatumIdColumn)
    ?.getColId();

  if (!columnIdItemId) {
    return;
  }

  const itemId: string = props.data[columnIdItemId].uuid;
  const datumId: string = columnIdDatumId ? props.data[columnIdDatumId].value : undefined;

  try {
    const { data: tableDefinitionData } = await sqTableOverridesApi.deleteOverridesFromColumn({
      id: props.tableDefinitionId,
      columnId: props.colDef.field,
      itemId,
      datumId,
    });
    setTableDefinition(tableDefinitionData);
    successToast({ messageKey: 'SCALING.OVERRIDE.OVERRIDE_DELETE_SUCCESS' });
    props.api.clearFocusedCell();
  } catch (e) {
    errorToast({ httpResponseOrError: e });
  }
};

const displayEvaluationDetails = (evaluationDetails: RulesResultV1[], columnId?: string) => {
  setEvaluationResultsToView(evaluationDetails);
  const evaluationColumn = sqTableDefinitionStore.columns.find((column) => column.id === columnId);
  setEvaluationDetailColumn(evaluationColumn);
  if (evaluationColumn) {
    setShowEvaluationDetailModal(true);
  }
};
