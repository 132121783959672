// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import _ from 'lodash';
import { ButtonWithPopover, PopoverConfig } from '@/core/ButtonWithPopover.molecule';

export interface ButtonWithPopoverIF extends ValidatingFormComponent<any> {
  component: 'ButtonWithPopoverFormComponent';
  popoverLabel: ({ className }) => React.ReactNode;
  popoverContent: ({ onChange, value }) => React.ReactNode;
  popoverConfig: PopoverConfig;
  closeOnClick?: boolean;
  labelClasses?: string;
}

export const ButtonWithPopoverFormComponent: React.FunctionComponent<ButtonWithPopoverIF> = (props) => {
  const {
    name,
    testId = 'buttonWithPopover',
    label,
    labelClasses,
    popoverLabel,
    popoverConfig,
    popoverContent,
    closeOnClick,
    extendValidation,
    validation,
    customErrorText,
  } = props;

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && _.has(formState.dirtyFields, name);

  const defaultValidation = _.isUndefined;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper testId={testId} label={label} showError={showError} customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { onChange, value, className } = getFormFieldProps(formState, input, meta, props);

          return (
            <ButtonWithPopover
              shouldTriggerFullWidth
              hasArrow
              extraClassNames={labelClasses}
              label={popoverLabel({ className })}
              popoverConfig={popoverConfig}
              closeOnClick={closeOnClick}>
              {popoverContent({ onChange, value })}
            </ButtonWithPopover>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
