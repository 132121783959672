// @ts-strict-ignore
import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { sqFoldersApi } from '@/sdk/api/FoldersApi';
import { flux } from '@/core/flux.module';
import { sqExploreWorkbookModalStore } from '@/core/core.stores';

export function setInitialFolderId(initialFolderId: string) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_INITIAL_FOLDER_ID', {
    initialFolderId,
  });
}

/**
 * Sets the workbook ID
 *
 * @param {String} workbookId - a workbook ID
 */
export function setWorkbookId(workbookId) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_WORKBOOK_ID', { workbookId });
}

/**
 * Sets the sort field that controls the sorting of the workbooks and toggles the direction if the field
 * was already set to the specified one.
 *
 * @param {String} sortProperty - One of WORKBOOKS_SORT_FIELDS
 * @param {boolean} sortAscending - True for asc, false for desc
 * @param {boolean} forceSortDirection=false - True if the given sort direction must not be flipped when sorting
 *   on the currently sorted property (normally used to toggle sort direction)
 */
export function setSort(sortProperty, ascending: boolean, forceSortDirection = false) {
  const sortAscending =
    !forceSortDirection && sortProperty === sqExploreWorkbookModalStore.sortProperty ? !ascending : ascending;
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_SORT', {
    sortProperty,
    sortAscending,
  });
}

/**
 * Clears the data for the currently loaded seeq content image.
 */
export function clear() {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_CLEAR');
}

export function setTableFilter(tableFilter) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_TABLE_FILTER', { tableFilter });
}

export function setSearchParams(field, value) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_SEARCH_PARAMS', { field, value });
}

export function setPageSizeForTable({ size }) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_PAGE_SIZE', { pageSize: size });
}

export function setPageNumberAndGo(page: number, loadTable: () => void) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_PAGE_NUMBER', {
    pageNumber: page,
  });
  loadTable();
}

export function setFolder(folder) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_FOLDER', { folder });
}

export function setShowResults(showResults) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_SHOW_RESULTS', { showResults });
}

export function setTableRoot(root) {
  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_TABLE_ROOT', { root });
}

export function loadItems() {
  const limit = sqExploreWorkbookModalStore.getPageSizeByTable();
  const currentPageNumber = sqExploreWorkbookModalStore.getPageNumberForTable();
  const offset = (currentPageNumber - 1) * limit;
  const sortDirection = sqExploreWorkbookModalStore.sortAscending ? 'asc' : 'desc';
  const sortOrder = `${sqExploreWorkbookModalStore.sortProperty} ${sortDirection}`;
  const root = sqExploreWorkbookModalStore.tableRoot;
  const params = _.assign({}, sqExploreWorkbookModalStore.searchParams, {
    limit,
    offset,
    sortOrder,
    root,
  });

  flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_LOADING', { loading: true });
  return sqFoldersApi
    .getFolders(params)
    .then((response) => {
      flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_ITEMS', {
        items: response.data.content,
      });
      flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_ITEM_TOTAL_FOR_TABLE', {
        itemTotalForTable: response.data.totalResults,
      });
    })
    .finally(() => flux.dispatch('EXPLORE_WORKBOOK_MODAL_SET_LOADING', { loading: false }));
}

export function getRootAndUpdateFilter(folderId) {
  if (folderId) {
    return sqFoldersApi.getAncestors({ folderId }).then((folderTree) => {
      const itemFolderRoot = folderTree.data.id;
      if (itemFolderRoot) {
        if (itemFolderRoot === SEARCH_ITEM_LOCATIONS.MY_FOLDER) {
          exposedForTesting.setTableFilter(SEARCH_ITEM_LOCATIONS.MY_FOLDER);
          exposedForTesting.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.MY_FOLDER);
        } else if (itemFolderRoot === SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC) {
          exposedForTesting.setTableFilter(SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);
          exposedForTesting.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC);
        } else if (itemFolderRoot === SEARCH_ITEM_LOCATIONS.CORPORATE) {
          exposedForTesting.setTableFilter(SEARCH_ITEM_LOCATIONS.CORPORATE);
          exposedForTesting.setSearchParams('filter', SEARCH_ITEM_LOCATIONS.CORPORATE);
        }
      }
    });
  } else {
    return Promise.resolve({});
  }
}

export const exposedForTesting = {
  setTableFilter,
  setSearchParams,
};
