import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserDatasources, setUserPreferencesDisplay } from '@/workbench/workbench.actions';
import { Button } from '@seeqdev/qomponents';
import { sqSearchStore, sqWorkbenchStore } from '@/core/core.stores';
import { useFlux } from '@/core/hooks/useFlux.hook';
import _ from 'lodash';
import { FakeLink } from '@/core/FakeLink';
import { isAdmin } from '@/services/authorization.service';
import { Datasource } from '@/search/search.types';
import { Checkbox } from '@/core/Checkbox.atom';
import { successToast } from '@/utilities/toast.utilities';
import { SEEQ_DATASOURCE } from '@/main/app.constants';
import { setWorkbookACL } from '@/workbook/workbook.actions';

/**
 * Tab to hide/show datasources based on user preferences; user can choose which
 * datasources show in their search filters.
 */
const getCheckboxBGColor = (isChecked: boolean) => {
  return isChecked ? 'sq-bg-included' : 'sq-bg-excluded';
};
export const SaveDatasourcePreferencesTab: React.FunctionComponent = () => {
  const { stateParams } = useFlux(sqWorkbenchStore);
  useEffect(() => {
    if (stateParams.workbookId) {
      setWorkbookACL();
    }
  }, [stateParams.workbookId]);
  const { t } = useTranslation();

  const { datasources } = useFlux(sqSearchStore);
  const { userDatasources } = useFlux(sqWorkbenchStore);
  const { includeNewDatasources } = useFlux(sqWorkbenchStore);

  const allPossibleDatasources = _.chain(datasources)
    .reject(
      (datasource) =>
        datasource.datasourceClass === SEEQ_DATASOURCE.datasourceClass || datasource.scopedTo !== undefined,
    )
    .sortBy('name')
    .value();

  const [isIncludeNewChecked, setIsIncludeNewChecked] = useState(includeNewDatasources);
  const [selectedDatasources, setSelectedDatasources] = useState<Datasource[]>(
    allPossibleDatasources.filter((datasource) =>
      includeNewDatasources ? !userDatasources.includes(datasource.id) : userDatasources.includes(datasource.id),
    ),
  );

  const [allCheckbox, setAllCheckbox] = useState(selectedDatasources.length > 0);
  const handleSelectAllCheckbox = () => {
    if (!allCheckbox) {
      setSelectedDatasources(allPossibleDatasources.map((datasource) => datasource));
      setAllCheckbox(true);
    } else {
      setSelectedDatasources([]);
    }
  };

  /**
   * Automatically unchecks the "All" checkbox if selectedDatasources reaches 0 in length
   */
  useEffect(() => {
    if (_.isEmpty(selectedDatasources)) {
      setAllCheckbox(false);
    }
  }, [selectedDatasources]);

  const handleSelectDatasource = (datasource: Datasource) => {
    if (!selectedDatasources.some((sd) => sd.id === datasource.id)) {
      setSelectedDatasources((prev) => [...prev, datasource]);
      setAllCheckbox(true);
    } else {
      setSelectedDatasources((prev) => prev.filter((existing) => existing.id !== datasource.id));
    }
  };

  const isChecked = (datasource: Datasource) => {
    return selectedDatasources.some((sd) => sd.id === datasource.id);
  };

  const onExcludeModeChange = (exclude: boolean) => {
    setIsIncludeNewChecked(exclude);
  };

  const RadioGroup = (
    <div className="ml5 mb10 flexAlignLeft pt4">
      <div className="mt10 mb5">
        <b className="mt5">{t('USER.DATASOURCE_PREFERENCES_NEWLY_ADDED')}</b>
      </div>
      <div className="form-check form-check-inline flexColumnContainer">
        <Checkbox
          id="datasource-preference-include"
          type="radio"
          onChange={() => onExcludeModeChange(true)}
          isChecked={isIncludeNewChecked}
          label={t('USER.DATASOURCE_INCLUDED_MODE')}
          classes="form-check-input"
        />
      </div>
      <div className="form-check form-check-inline flexColumnContainer">
        <Checkbox
          id="datasource-preference-exclude"
          type="radio"
          onChange={() => onExcludeModeChange(false)}
          isChecked={!isIncludeNewChecked}
          label={t('USER.DATASOURCE_EXCLUDED_MODE')}
          classes="form-check-input"
        />
      </div>
    </div>
  );

  return (
    <div className="flexRowContainer overflowHidden" data-testid="saveDatasourcePreferencesTab">
      <div className="pb10 flexColumnContainer flexSpaceBetween">
        <b className="mb5">{t('USER.DATASOURCE_PREFERENCES_TITLE')}</b>
        {isAdmin() && (
          <FakeLink
            testId="manage-datasources"
            onClick={() => {
              window.open('/administration', 'SeeqAdmin');
            }}>
            {t('DATASOURCES.MANAGEMENT')}
          </FakeLink>
        )}
      </div>
      <div className="mb5">{t('USER.DATASOURCE_PREFERENCES_DETAILS')}</div>
      <div className="pl3">
        <Checkbox
          id={`checkbox-toggle-all-datasources`}
          label={t('USER.DATASOURCE_PREFERENCES_ALL_DATASOURCES_CHECKBOX_LABEL', {
            count: selectedDatasources.length,
            total: allPossibleDatasources.length,
          })}
          classes={'pl2 pb5 pt5'}
          isChecked={allCheckbox || selectedDatasources.length > 0}
          onClick={() => handleSelectAllCheckbox()}
        />
      </div>
      <div className="overflowYScroll height-200 mt5">
        {allPossibleDatasources.map((datasource) => (
          <div key={datasource.id} className={`pl3 ${getCheckboxBGColor(isChecked(datasource))}`}>
            <Checkbox
              id={`checkbox-${datasource.id}`}
              label={`${datasource.name}`}
              classes="l2 pb5 pt5"
              isChecked={isChecked(datasource)}
              onClick={() => handleSelectDatasource(datasource)}
            />
          </div>
        ))}
      </div>
      {RadioGroup}
      <div className="pt20 flexRowContainer flexAlignCenter">
        <Button
          testId="saveDatasourcePreferencesButton"
          type="button"
          extraClassNames="buttonDisabledTooltip"
          size="sm"
          variant="theme"
          onClick={() => {
            const updatedDatasources = isIncludeNewChecked
              ? datasources.filter((ds) => !selectedDatasources.includes(ds)).map((datasource) => datasource.id)
              : selectedDatasources.map((datasource) => datasource.id);
            setUserDatasources(updatedDatasources, isIncludeNewChecked);
            successToast({ messageKey: 'USER.DATASOURCE_PREFERENCES_SAVED_TOAST' });
            setUserPreferencesDisplay(false);
          }}
          tooltip={t('USER.DATASOURCE_SAVE_PREFERENCES_BUTTON_TOOLTIP')}
          label={t('USER.SAVE_DATASOURCE_CHANGES_BUTTON')}
        />
      </div>
    </div>
  );
};
