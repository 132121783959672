import _ from 'lodash';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { sqWorkbooksApi } from '@/sdk/api/WorkbooksApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { parseISODate } from '@/datetime/dateTime.utilities';
import { WorksheetOutputV1 } from '@/sdk';
import { StoreWorksheet } from '@/worksheet/worksheet.types';

export const DEFAULT_WORKSHEET_NAME = 'worksheet';

/**
 * Get the Worksheets for a workbook.
 *
 * @param workbookId - The GUID of the workbook to be fetched
 * @param [includeWorkstepId=false] - true to have worksheets include the current workstep ID
 * @param [includeArchivedWorksheets=false] - true to return the workbook's archived worksheets
 * @return resolves with an array of worksheet objects
 */
export function getWorksheets(
  workbookId: string,
  includeWorkstepId = false,
  includeArchivedWorksheets = false,
): Promise<StoreWorksheet[]> {
  // Would probably be best to integrate pagination with infinite worksheet scrolling.
  // For now, just make the limit so high that it won't be an issue.
  return sqWorkbooksApi
    .getWorksheets({ workbookId }, { params: { limit: 10000, isArchived: includeArchivedWorksheets } })
    .then(({ data }) => _.map(data.worksheets, (worksheet) => toWorksheet(worksheet, includeWorkstepId)));
}

/**
 * Gets a Worksheet.
 *
 * @param workbookId - a workbook ID
 * @param worksheetId - a worksheet ID
 * @param [includeArchivedWorksheets=false] - true to return the workbook's archived worksheets
 *
 * @return Promise that is resolved when the call completes.
 */
export function getWorksheet(workbookId: string, worksheetId: string, includeArchivedWorksheets = false) {
  return sqWorkbooksApi
    .getWorksheet({ workbookId, worksheetId }, { params: { isArchived: includeArchivedWorksheets } })
    .then((worksheet) => toWorksheet(worksheet.data));
}

/**
 * Creates a Worksheet.
 *
 * @param  workbookId - ID of the workbook on which to create the worksheet
 * @param  name - Name for the new worksheet
 * @return resolves when the worksheet has been created
 */
export function createWorksheet(workbookId: string, name: string) {
  name = name || DEFAULT_WORKSHEET_NAME;
  return sqWorkbooksApi.createWorksheet({ name }, { workbookId }).then((worksheet) => toWorksheet(worksheet.data));
}

/**
 * Updates a worksheet's name.
 *
 * @param worksheetId - GUID of the worksheet to be renamed
 * @param name - The new name for the worksheet
 * @returns A promise that will resolve when the worksheet
 * has been successfully renamed; or reject if it was not renamed.
 */
export function setName(worksheetId: string, name: string) {
  return sqItemsApi.setProperty({ value: name }, { id: worksheetId, propertyName: SeeqNames.Properties.Name });
}

/**
 * Archives a Worksheet.
 *
 * @param worksheetId - The GUID of the worksheet to be archived.
 * @return Promise that is resolved when the worksheet has been archived.
 * @example archiveWorksheet(worksheetId);
 */
export function archiveWorksheet(worksheetId: string) {
  return sqItemsApi.setProperty({ value: true }, { id: worksheetId, propertyName: SeeqNames.Properties.Archived });
}

/**
 * Restores (unarchives) a Worksheet.
 *
 * @param worksheetId - The GUID of the worksheet to be restored.
 *
 * @return Promise that is resolved when the worksheet has been restored.
 */
export function restoreWorksheet(worksheetId: string) {
  return sqItemsApi.setProperty({ value: false }, { id: worksheetId, propertyName: SeeqNames.Properties.Archived });
}

/**
 * Moves a Worksheet.
 *
 * @param workbookId - ID of the workbook on which the worksheet exists
 * @param worksheetId - ID of the worksheet to move
 * @param nextWorksheetId - ID of the worksheet marking the new location. If undefined, worksheet is moved to the end.
 * @return Promise that is resolved when the call completes.
 */
export function moveWorksheet(workbookId: string, worksheetId: string, nextWorksheetId?: string) {
  return sqWorkbooksApi.moveWorksheet({
    workbookId,
    worksheetId,
    nextWorksheetId,
  });
}

/**
 * Helper function that creates a worksheet object to be dispatched to a store
 *
 * @param worksheet - The worksheet from the API
 * @param [includeWorkstepId=false] - true to have worksheets include the current workstep ID
 * @returns  Worksheet for a store
 */
export function toWorksheet(worksheet: WorksheetOutputV1 | undefined, includeWorkstepId = false): StoreWorksheet {
  return _.omitBy(
    {
      name: worksheet?.name,
      createdAt: worksheet && worksheet.createdAt ? parseISODate(worksheet?.createdAt).valueOf() : undefined,
      updatedAt: worksheet && worksheet.updatedAt ? parseISODate(worksheet?.updatedAt).valueOf() : undefined,
      worksheetId: worksheet?.id,
      reportId: _.get(worksheet, 'report.id'),
      workstepId: includeWorkstepId ? worksheet?.workstep : undefined,
      duplicationErrorDocument: worksheet?.duplicationErrorDocument,
    },
    _.isNil,
  );
}
