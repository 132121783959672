import React from 'react';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { identifyUser, initializeTrackers, resetTrackingData } from '@/track/track.service';

/**
 * Tracker is responsible for initializing the Telemetry trackers.
 *
 * This component was created to ensure that all tracking events use current store data.
 */
export const Tracker: React.FunctionComponent = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);
  const userLanguage = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.userLanguage);
  const license = useFluxPath(sqLicenseManagementStore, () => sqLicenseManagementStore.license);

  initializeTrackers(currentUser, darkMode, userLanguage, license);
  if (Object.keys(currentUser).length > 0) {
    identifyUser(currentUser.email);
  } else {
    resetTrackingData();
  }

  return null;
};
