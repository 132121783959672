export type FontSize = 'small' | 'medium' | 'large';

export type ChatMessage = {
  role: 'bot' | 'user';
  dialog: string;
  id: string;
  chatId?: string;
  agentType?: string;
  last?: boolean;
  agent?: string;
  origin?: AssistantOrigin;
  originDetails?: AssistantOriginDetails;
  stages?: Record<string, string | string[] | object>;
  components?: string[];
};

export type AgentType = 'formula' | 'general' | 'code' | 'workbench' | 'vantage';

export const assistantLocations = {
  homescreen: 'Home Screen',
  workbench: 'Workbench',
  organizer: 'Organizer',
  datalab: 'Data Lab',
} as const;

export type AssistantOrigin = keyof typeof assistantLocations;
export type AssistantOriginDetails = { label: string; url: string };

/**
 * DO NOT ACCESS THIS DIRECTLY. Use `genAIAgents()`  or getDataLabAiAgents() instead.
 */
export const agents: Agent[] = [
  {
    name: 'General',
    color: '#39516b',
    key: 'general',
    icon: 'fc-genai-chat',
    description: 'AI_ASSISTANT.AGENT_INTRO_GENERAL',
  },
  {
    name: 'Formula',
    color: '#427c63',
    key: 'formula',
    icon: 'fc-formula-ai',
    description: 'AI_ASSISTANT.AGENT_INTRO_FORMULA',
  },
  {
    name: 'Data Lab',
    color: '#f26722',
    key: 'code',
    icon: 'fc-datalab-ai',
    description: 'AI_ASSISTANT.AGENT_INTRO_DATA_LAB',
  },
  {
    name: 'Actions',
    color: '#6f42c1',
    key: 'workbench',
    icon: 'fc-workbook-ai',
    description: 'AI_ASSISTANT.AGENT_INTRO_ACTIONS',
  },
  {
    name: 'Vantage',
    color: '#0491c2',
    key: 'vantage',
    icon: 'fc-workbook-ai',
    description: 'AI_ASSISTANT.AGENT_INTRO_VANTAGE',
  },
];

export interface Agent {
  name: string;
  color: string;
  key: AgentType;
  icon: string;
  description: string;
}

export type LevelOfSatisfaction = 'notAtAll' | 'notReally' | 'neutral' | 'satisfied' | 'verySatisfied';
type ProblemType =
  | 'wrong'
  | 'tooComplex'
  | 'other'
  | 'unclear'
  | 'concise'
  | 'accurate'
  | 'detailed'
  | 'moreConcise'
  | 'moreDetail'
  | 'moreAccuracy'
  | 'examples';
export type AnswerType = {
  problemKey: ProblemType;
  displayKey: string;
  correctionSuggestion?: string;
  correctionPrompt?: string;
  showInput?: boolean;
};
export type SatisfactionEntry = {
  icon: string;
  satisfaction: LevelOfSatisfaction;
  title: string;
  text?: string;
  options?: AnswerType[];
};

// To ADD feature flag for this
export const getDataLabAiAgents = () => {
  return 'general,formula,code';
};
